<template>
  <ChatWrapper class="www footer">
    <!-- <div class="create-action">
      <sdButton class="btn-add" size="default" type="default" shape="circle" block>
        <sdFeatherIcons type="edit" size="14" />
        Create New Group
      </sdButton>
    </div> -->
    <!-- <a-input-group size="large">
      <a-input ref="userNameInput" v-model:value="search" placeholder="Search">
        <template #prefix>
          <search-outlined type="user" />
        </template>
      </a-input>
    </a-input-group> -->
    <div v-if="treatments.length !== 0">
      
    
    <ul v-if="!loader">
      <li v-for="treatment in filteredTreatments"  :key="treatment.id" class="chat-link-signle ">
        <!-- <router-link to='group/1'> -->
      <div v-if="treatment.treatment.name !== 'Covid-19'">

        <a  :key="{ name: 'groupSingle', params: { id: treatment.casenum, name: treatment.treatment.name } }" @click="reloadThis(treatment.casenum,treatment.treatment.name)" class="ant-row"  :class="{'highlight': (treatment.casenum === casenum)}">
          <!-- <a :href='"/#/app/chat/group/" +  treatment.casenum + "/" + treatment.treatment.name' class="ant-row"> -->
          <a-col :xl="6">
            <div class="author-figure">
              <img :src="treatment.treatment.image"  alt="" />
            </div>
          </a-col>

          <a-col :xl="18">
            <BlockSpan class="author-name">{{ treatment.treatment.name }}</BlockSpan>
            <BlockSpan class="author-chatText">
              <!-- {{ textRefactor(treatment.prescription_status, 5) }} -->
            </BlockSpan>
          </a-col>
          <!-- </a> -->
        </a>
      </div>
      <div v-else class="ant-row">

          <a-col :xl="6">
            <div class="author-figure">
              <img :src="require(`../../../../static/img/chat-author/g1.png`)" alt="" />
            </div>
          </a-col>

          <a-col :xl="18">
            <BlockSpan class="author-name">Not available</BlockSpan>
            <BlockSpan class="author-chatText">
                We don't support messages for covid-19
            </BlockSpan>
          </a-col>
        

      </div>

      </li>
    </ul>
    <div v-else>
      <Main class="footer">
        <div class="loaderstyle">
          <div class="loader"></div>
        </div>
      </Main>
    </div>
    </div>
    <div v-else>
        <!-- <li class="chat-link-signle"> -->

      <!-- <a-col :xl="6" class="chat-link-single">
            <div class="author-figure">
              <img :src="require(`../../../../static/img/chat-author/g1.png`)" alt="" />
            </div>
          </a-col> -->

          <a-col :xl="18" style="padding-left:20px">
             <!-- <div class="author-figure">
              <img :src="require(`../../../../static/img/chat-author/g1.png`)" alt="" />
            </div> -->
            <BlockSpan class="author-name">Not available</BlockSpan>
            <BlockSpan class="author-chatText">
                We don't support messages for covid-19
            </BlockSpan>
          </a-col>
          <!-- </li> -->
    </div>
  </ChatWrapper>
</template>
<script>
import moment from 'moment';
import { BlockSpan, ChatWrapper } from '../style';
import { textRefactor } from '../../../../components/utilities/utilities';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { reactive } from 'vue';

const GroupChat = {
  name: 'GroupChat',
  components: { BlockSpan, ChatWrapper },
  data() {
    return {
      treatments: [],
      loader: true,
      search: null,
      casenum: null,
    };
  },
  methods:{
    reloadThis(a,b){
      this.casenum = a
      // { name: 'groupSingle', params: { id: treatment.casenum, name: treatment.treatment.name } }
      this.$router.push({ name: 'groupSingle', params: { id: a, name: b }})

      // this.$router.go(1)
      // alert(window.location.href)
      // window.location.href = window.location.href 
      // window.location.reload()
    },
    chatShow(){
      const url = window.location.href;
      //const match = url.replace(/[\D]/g, '')
      var casenum = url.replace(/[\D]/g, '')
      // alert(casenum)
      // var casenum = '03232210404476477'
      this.casenum= casenum
      console.log(casenum)
    }
  },
  async mounted() {
    this.chatShow()
    await axios
      .get('group-chat.php')
      //.get('https://api.getbetterdoctor.com/api/customers')
      .then(response => {
        this.treatments = response.data.orders.filter(function(e) { return e.treatment.name !== 'Covid-19' });
        console.clear()
        console.log(this.treatments.length)
      })
      .catch(err => {
        console.log('err');
        console.log(err);
      });
    this.loader = false;
  },
  computed: {
    filteredTreatments() {
      if (this.search !== null && this.search !== '') {
        return this.treatments.filter(a => a.treatment.name.toLowerCase().includes(this.search.toLowerCase()));
      } else {
        return this.treatments;
      }
    },
  },
  async chatClicked() {
    console.log('Here');
  },
  async setup() {
    const { state, dispatch } = useStore();
    const match = computed(() => useRoute());
    // const chatData = computed(() =>
    //   state.chat.groupChat.data.sort((a, b) => {
    //     return b.time - a.time;
    //   }),
    // );
    // console.log('chatData')
    // console.log(chatData)

    //get case number
    let treatments = [];
    // const dataFiltering = (e, id) => {
    //   e.preventDefault();
    //   dispatch('filterSinglepageGroup', id);
    // };
    return {
      match,
      // chatData,
      // dataFiltering,
      moment,
      textRefactor,
    };
  },
};

export default GroupChat;
</script>
<style scoped>
.highlight{
  background-color: #63d4d6 !important;

}
.footer {
  min-height: calc(100vh - 114px);
}
.author-figure {
  padding-left: 10px;
}
.author-name {
  color: #44566c;
  font-size: 1rem !important;
}
.author-chatText {
  color: #8697a8 !important;
  font-size: 0.8125rem !important;
}
.author-info {
  padding-left: 15%;
}
</style>
<style>
/* @media (min-width: 1201px) and (max-width: 1700px) {
  .ant-card-body {
    border: 1px solid #f0f0f0;
    height: 220px;
    width: 100%;
  }
} */
</style>
